@include exports( 'ripple/layout' ) {

    .k-ripple {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        overflow: hidden;
        pointer-events: none;
    }

    .k-ripple-blob {
        pointer-events: none;
        position: absolute;
        border-radius: 50%;
        padding: 0;
        transform: translate(-50%, -50%) scale(0);
        transition: opacity 100ms linear, transform 500ms cubic-bezier(.4, 0, .2, 1);
        opacity: .1;
        background-color: currentColor;

        .k-primary & {
            opacity: .2;
        }

        .k-primary.k-flat & {
            opacity: 1;
        }
    }

}
