@include exports("numerictextbox/layout") {

    // Base
    .k-numerictextbox {

        .k-rtl &,
        &[dir='rtl'] {
            .k-numeric-wrap .k-i-warning {
                align-self: center;
                margin-right: 0;
                margin-left: $spacer-x / 2;
            }
        }
    }

    .k-numeric-wrap {
        .k-i-warning {
            align-self: center;
            margin-right: $spacer-x / 2;
        }

        > .k-input {
            flex: 1 1 0;
        }
    }
}
