@include exports("numerictextbox/theme") {
    $numerictextbox-icon-offset: 1px !default;

    // Numeric
    .k-numerictextbox {

        // Normal state
        .k-numeric-wrap {
            @include appearance( input );
            transition: $transition;
        }
        .k-select {
            @include appearance( button );
            transition: $transition;
        }

        // Hovered state
        .k-state-hover {
            @include appearance( hovered-input );

            .k-select {
                @include appearance( hovered-button );
            }
        }

        // Focused state
        .k-state-focused {
            @include appearance( focused-input );
        }

        // Selected state
        .k-link.k-state-selected {
            @include appearance( pressed-button );
        }

        .k-link-increase > .k-icon {
            bottom: -$numerictextbox-icon-offset;
        }

        .k-link-decrease > .k-icon {
            top: -$numerictextbox-icon-offset;
        }

        .k-numeric-wrap.k-state-invalid {
            border-color: $error;
            color: $error;
        }
    }
}