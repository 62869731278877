$is-light: lightness($bg-color) > 50;

$resolved-tooltip-text: if($is-light, $tooltip-color, $tooltip-bg);
$resolved-tooltip-bg: if($is-light, $tooltip-bg, $tooltip-color);

@include exports("tooltip/theme") {

    .k-tooltip {
        color: $resolved-tooltip-text;
        background-color: $resolved-tooltip-bg;
        @include border-radius($border-radius);
    }

    .k-tooltip-closable {
        padding: $padding-y $padding-x;
    }


    .k-callout-n { border-bottom-color: $resolved-tooltip-bg; }
    .k-callout-e { border-left-color: $resolved-tooltip-bg; }
    .k-callout-s { border-top-color: $resolved-tooltip-bg; }
    .k-callout-w { border-right-color: $resolved-tooltip-bg; }

}