// TODO: extract or remove tooltip padding variables
$tooltip-padding-y: $padding-y !default;
$tooltip-padding-x: $padding-x !default;
$tooltip-callout-size: 12px !default;

@include exports("tooltip/layout") {

    .k-tooltip {
        @include border-radius();
        padding: $tooltip-padding-y $tooltip-padding-x;
        border-width: 0;
        background-repeat: repeat-x;
        position: absolute;
        z-index: 12000;
    }

    .k-tooltip-closable {
        padding: ($padding-y * 3) ($padding-x * 2);
    }

    .k-tooltip-closable .k-tooltip-content {
        padding-right: (5 * $icon-size) / 4;
    }

    .k-tooltip-button {
        height: 0;
        text-align: right;
        margin-right: -2px;

        .k-icon {
            color: inherit;
            vertical-align: top;
        }
    }

    // Callout
    .k-callout {
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px;
        border-color: transparent;
        pointer-events: none;
    }

    .k-callout-n {
        top: -$tooltip-callout-size;
        left: 50%;
        pointer-events: none;
    }

    .k-callout-w {
        top: 50%;
        left: -$tooltip-callout-size;
        pointer-events: none;
    }

    .k-callout-s {
        left: 50%;
        bottom: -$tooltip-callout-size;
        pointer-events: none;
    }

    .k-callout-e {
        top: 50%;
        right: -$tooltip-callout-size;
        pointer-events: none;
    }
}
